@import "../variables";
.top-page {
    overflow-x: hidden;
    &.layout-detail {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    &.display-header-register {
        padding-bottom: 5.188rem;
        height: auto;
    }
    .layout-container {
        -webkit-flex: 1 1 100%;
        -moz-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
        -o-flex: 1 1 100%;
        -khtml-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: 100%;
        display: flex;
        .page-body {
            -webkit-flex: 1 1 100%;
            -moz-flex: 1 1 100%;
            -ms-flex: 1 1 100%;
            -o-flex: 1 1 100%;
            -khtml-flex: 1 1 100%;
            flex: 1 1 100%;
            max-width: 100%;
            background: url("../../images/layout/background-top-after.png") no-repeat center center;
            background-size: cover;
        }
        .top-content {
            position: relative;
            height: 100%;
            overflow: hidden;
            .top-banner {
                position: relative;
                top: calc(100% - 235px);
            }
            .banner {
                background-color: rgba(255, 255, 255, 0.65);
                padding: 1rem;
                width: 70%;
                max-width: 50rem;
                margin: auto;
                .greeting {
                    text-align: center;
                    color: inherit;
                    margin-bottom: .1rem;
                    font-size: 1.75rem;
                }
                .logo {
                    text-align: center;
                    padding: .8rem;
                    // background-color: $key-color;
                    background-color: #00267F; //static color for only fujis
                    img {
                        max-height: 3.5rem;
                        max-width: 100%;
                    }
                }
            }
            .board-projector {
                position: absolute;
                top: 7%;
                display: flex;
                justify-content: center;
                left: 50%;
                max-width: 100%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                .board-item {
                    height: 310px;
                    max-height: 43vh;
                    width: 500px;
                    padding: 0.3rem;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    .screen {
                        overflow: hidden;
                        position: relative;
                        height: 100%;
                        width: 100%;
                        cursor: pointer;
                        &:hover .board-pc {
                            -webkit-filter: contrast(30%);
                            filter: contrast(30%);
                        }
                        &:hover .text-board {
                            opacity: 1;
                        }
                        &::after {
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                        }
                        img {
                            position: relative;
                            z-index: 1;
                        }
                        .text-board {
                            color: $white;
                            text-align: center;
                            position: absolute;
                            z-index: 3;
                            font-weight: normal;
                            font-size: 1.3rem;
                            left: 50%;
                            top: 54%;
                            width: 87%;
                            opacity: 0;
                            -webkit-transform: translate(-50%, -5%);
                            -moz-transform: translate(-50%, -5%);
                            -ms-transform: translate(-50%, -5%);
                            -o-transform: translate(-50%, -5%);
                            transform: translate(-50%, -5%);
                        }
                        //.text-board-left {
                        //    -webkit-transform: rotate(-5deg);
                        //    -moz-transform: rotate(-5deg);
                        //    -ms-transform: rotate(-5deg);
                        //    -o-transform: rotate(-5deg);
                        //    transform: rotate(-5deg);
                        //    left: 5%;
                        //    top: 36%;
                        //}
                        //.text-board-right {
                        //    -webkit-transform: rotate(4deg);
                        //    -moz-transform: rotate(4deg);
                        //    -ms-transform: rotate(4deg);
                        //    -o-transform: rotate(4deg);
                        //    transform: rotate(4deg);
                        //    left: 3%;
                        //    top: 36%;
                        //}
                    }
                    &.board-left {
                        position: relative;
                        perspective-origin: left;
                        perspective: 300px;
                    }
                    &.board-right {
                        position: relative;
                        perspective-origin: right;
                        perspective: 300px;
                    }
                    &.board-center {
                        position: relative;
                        perspective-origin: top;
                        perspective: 300px;
                    }
                }
            }
            .footer {
                padding: .2rem 1rem;
                position: relative;
                top: calc(100% - 222px);
                color: white;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .top-page {
        .layout-container {
            .top-content {
                .board-projector {
                    .board-item {
                        .screen {
                            .text-board {
                                font-size: 1.2rem;
                                line-height: 1.3;

                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 700px) and (min-width: 768px) {
    .top-page {
        .layout-container {
            min-height: 630px;

            .top-content {
                .board-projector {
                    .board-item {
                        height: 295px;
                        max-height: 1000px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px),
screen and (max-height: 480px) {
    .top-page {
        .layout-container {
            .page-body {
                background: transparent;
            }
            .top-content {
                height: auto;
                overflow: initial;
                background-color: $key-color;
                color: $white;
                display: flex;
                -webkit-flex-direction: column;
                -moz-flex-direction: column;
                -ms-flex-direction: column;
                -o-flex-direction: column;
                -khtml-flex-direction: column;
                flex-direction: column;
                .top-banner {
                    background: url("../../images/layout/background-top-after.png") no-repeat center center;
                    background-size: cover;
                    width: 100%;
                    top: auto;
                    padding: 2rem .5rem;
                }
                .banner {
                    width: 100%;
                    padding: .8rem;
                    color: $black;
                    .greeting {
                        font-size: 1.125rem;
                    }
                    .logo {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }
                }
                .board-projector {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: flex;
                    flex-direction: column;
                    -webkit-flex: 1 1 auto;
                    -moz-flex: 1 1 auto;
                    -ms-flex: 1 1 auto;
                    -o-flex: 1 1 auto;
                    -khtml-flex: 1 1 auto;
                    flex: 1 1 auto;
                    align-items: center;
                    justify-content: center;
                    -webkit-transform: none;
                    -moz-transform: none;
                    -ms-transform: none;
                    -o-transform: none;
                    transform: none;
                    .board-item {
                        height: auto;
                        width: auto;
                        max-width: 500px !important;
                        padding: 0 .5rem;
                        margin: auto;
                        max-height: 100000px;
                        &.board-left {
                            order: 1;
                        }
                        &.board-center {
                            order: 2;
                        }
                        &.board-right {
                            order: 3;
                        }
                        .board-help {
                            color: $white;
                            //text-align: center;
                            margin-top: .5rem;
                            margin-left: auto;
                            margin-right: auto;
                            font-size: 14px;
                            br {
                                display: none;
                            }
                        }
                        .screen {
                            margin: 1.7rem auto 0 auto;
                            height: 110px;
                            max-width: 400px;
                            -webkit-transform: none !important;
                            -moz-transform: none !important;
                            -ms-transform: none !important;
                            -o-transform: none !important;
                            transform: none !important;
                            .board-pc {
                                display: none;
                            }
                            .text-board {
                                display: none;
                            }
                            .text-board-mobile {
                                display: none;
                                color: $white;
                                text-align: center;
                                position: absolute;
                                z-index: 3;
                                font-weight: normal;
                                font-size: 2.5rem;
                                left: 50%;
                                top: 50%;
                                width: 90%;
                                text-shadow: 0px 0px 4px #fff;
                                -webkit-transform: translate(-50%, -50%);
                                -moz-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                -o-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
                .footer {
                    position: relative;
                    top: auto;
                    margin-top: 2rem;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

.section-information-magazine{
    a{
        transition: 1.0s ;
        &:hover{
            opacity: 0.5 ;
        }
    }
    img{
        max-width: 750px;
        margin: auto;
    }
}